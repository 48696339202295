// build-time variables

export const PRODUCTION = process.env.NODE_ENV === 'production';
export const VUE_APP_NODE_ENV = getMetaEnv('VUE_APP_NODE_ENV', process.env.VUE_APP_NODE_ENV);
export const PUBLIC_PATH = process.env.PUBLIC_PATH;

// run-time variables with build-time fallback

export const API_ENDPOINT = getMetaEnv('VUE_APP_API_ENDPOINT', process.env.VUE_APP_API_ENDPOINT ?? '/graphql');
export const ORIGIN_ENDPOINT = new URL(API_ENDPOINT).origin;
export const ORIGIN_ENDPOINT_DECODERS = getMetaEnv(
  'VUE_APP_ORIGIN_ENDPOINT_DECODERS',
  process.env.VUE_APP_ORIGIN_ENDPOINT_DECODERS,
);
export const PREFIX_URL = getMetaEnv('VUE_APP_PREFIX_URL', process.env.VUE_APP_PREFIX_URL).replace(/\/+$/, '');
export const PREZI_LINK = getMetaEnv('VUE_APP_PREZI_LINK', process.env.VUE_APP_PREZI_LINK);
export const KEYCLOAK_URL = getMetaEnv('VUE_APP_KEYCLOAK_URL', process.env.VUE_APP_KEYCLOAK_URL);
export const KEYCLOAK_REALM = getMetaEnv('VUE_APP_KEYCLOAK_REALM', process.env.VUE_APP_KEYCLOAK_REALM);
export const KEYCLOAK_CLIENT_ID = getMetaEnv('VUE_APP_KEYCLOAK_CLIENT_ID', process.env.VUE_APP_KEYCLOAK_CLIENT_ID);
export const LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID = getMetaEnv(
  'VUE_APP_LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID',
  process.env.VUE_APP_LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID,
);
export const API_HEATING_SYSTEM_COLLECTOR_ENDPOINT = getMetaEnv(
  'VUE_APP_API_HEATING_SYSTEM_COLLECTOR_ENDPOINT',
  process.env.VUE_APP_API_HEATING_SYSTEM_COLLECTOR_ENDPOINT,
);
export const API_CONSUMPTION_ENDPOINT = getMetaEnv(
  'VUE_APP_API_CONSUMPTION_ENDPOINT',
  process.env.VUE_APP_API_CONSUMPTION_ENDPOINT,
);
export const API_GENERIC_NOTIFICATION_ENDPOINT = getMetaEnv(
  'VUE_APP_API_GENERIC_NOTIFICATION_ENDPOINT',
  process.env.VUE_APP_API_GENERIC_NOTIFICATION_ENDPOINT,
);

function getMetaEnv(name: string, def = ''): string {
  return document.querySelector(`meta[data-env][name=${name}]`)?.getAttribute('content') ?? def;
}
// Name for default service provider for a project that has none set
export const DEFAULT_PROJECT_SERVICE_PROVIDER = getMetaEnv(
  'VUE_APP_DEFAULT_PROJECT_SERVICE_PROVIDER',
  process.env.VUE_APP_DEFAULT_PROJECT_SERVICE_PROVIDER,
);
export const DEVICE_REGISTER_SAVE_ENDPOINT = getMetaEnv(
  'VUE_APP_DEVICE_REGISTER_SAVE_ENDPOINT',
  process.env.VUE_APP_DEVICE_REGISTER_SAVE_ENDPOINT,
);

export const ADMIN_APP_ENDPOINT = getMetaEnv('VUE_APP_ADMIN_APP_ENDPOINT', process.env.VUE_APP_ADMIN_APP_ENDPOINT);
